<template>
  <div class="h-full w-full ">
    <div class=" overflow-hidden w-full">
      <div class="page-content-2 ">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div style="padding-top: 58%" class="bg-buy flex flex-col justify-center items-center">
<!--            <img src="../../assets/img/profile/text_img_bg.png"-->
<!--            style="height: 120px ;margin-bottom: 20px;">-->
              <div class="w-full px-4 ">
                <div class="bg-FEF9F1 w-full p-2 flex flex-col mb-4 rounded bg-white  "
                     v-for="(item,index) in list"
                     :key="index">


                  <div class="flex flex-row items-center justify-between p-2">

                    <div class="flex flex-row items-center mb-2">
                      <img src="../../assets/img/profile/icon_reverse.png" style="height: 60px"/>
                      <div class="ml-2">
                        <div class="text-black font-bold text-normal">恢复五行天下资产</div>
                        <div class="text-red-600 text-sm my-2"> <span class="text-gray-400">恢复额度：</span>{{ item.gift_coin_num}}人民币</div>
                        <div class=" text-green-500 text-sm"><span class="text-gray-400">缴纳金额：</span>{{item.price|formatDecimal2 }}</div>

                      </div>
                    </div>

                    <div class="btn rounded-lg py-2 text-white font-bold text-base px-4 text-center whitespace-nowrap overflow-hidden text-ellipsis"
                         style="margin-top: 40px; height: 40px; min-width: max-content;"
                         @click="jumpToPay(item)">
                      激活恢复
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </list>
          <Empty v-if="isNullOrEmpty(list)&&loading===false&&refreshing==false" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>
import {Empty, List, PullRefresh} from "vant";
import {isNullOrEmpty} from "@/utils";
import axios from "@/net/axios";
import api from "@/net/api";
import moment from "moment";

export default {
  name: "Fund",
  components: {List, Empty, PullRefresh},
  data() {
    return {
      isNullOrEmpty,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      dateTime: moment().format('YYYY年MM月DD日HH时mm分')
    };
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  filters: {
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      return parseFloat(value / 10000).toFixed(0);
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios.get(api.welfareList,{params: {type: 1}})
          .then(res => {
            this.list = res.data.idoList
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch(err => {
            this.loading = false
            this.finished = true
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToPay(data) {
      this.$router.push({
        name: "buyDividend",
        query: {
          id: data.id,
          stock: data.gift_coin_num,
          price: data.price,
          title: data.title,
          subtitle: '恢复额度',
          source:2,
        },
      });
    },
    changePage() {
      this.$router.push({
        name: "fund", // 跳转到我的资产页面
      });
    }
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-body {

}
.bg-buy{
  background-image: url('../../assets/img/profile/img_bg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
}


.tab {
  width: 126px;
  height: 40px;
  line-height: 40px;
}

.tab-checked {
  width: 126px;
  height: 40px;
  line-height: 40px;
}


.btn{
  background: linear-gradient(90deg, #FF5455, #FFB533);
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

</style>
