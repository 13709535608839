<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="overflow-y-auto h-full">

        <div class="flex flex-row items-center pt-2justify-between text-base bg-white px-2 pb-2">
          <div>提现金额(元)</div>
        </div>

        <div class="flex flex-row items-center flex-wrap bg-white px-2 pb-2">
          <div
              class="w-1/4 flex flex-row items-center mb-2 "
              v-for="(item,index) in getCurrentList" :key="index" @click="onChangeIndex(index)">
            <div class="flex rounded bg-white w-11/12  px-2 py-2 "
                 :class="{'bg-primary text-white':selectIndex==index,'border text-black':selectIndex!=index}">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="selectIndex==0 && withdrawTo !== 'bank'" class="text-red-500 bg-white px-2">优先提现</div>

        <Form @submit="onSubmit">

          <Field name="radio" label="提现至" label-width="80px" label-class="text-sm">
            <template #input>
              <RadioGroup v-model="withdrawTo" direction="horizontal" class="w-full">
                <div class="flex justify-start w-full">
                  <Radio name="wechat" class="custom-radio mr-4">
                    <template #icon="props">
                      <div :class="['custom-radio-icon', { 'custom-radio-icon-checked': props.checked }]"></div>
                    </template>
                    微信
                  </Radio>
                  <Radio name="alipay" class="custom-radio mr-4">
                    <template #icon="props">
                      <div :class="['custom-radio-icon', { 'custom-radio-icon-checked': props.checked }]"></div>
                    </template>
                    支付宝
                  </Radio>
                  <Radio name="bank" class="custom-radio">
                    <template #icon="props">
                      <div :class="['custom-radio-icon', { 'custom-radio-icon-checked': props.checked }]"></div>
                    </template>
                    银行卡
                  </Radio>
                </div>
              </RadioGroup>
            </template>
          </Field>

          <template v-if="withdrawTo !== 'bank'">
            <Field
                v-model="name"
                label="姓名"
                placeholder="请填写您的真实姓名"
                label-width="80px"
                label-class="text-sm"
            />

            <Field
                v-model="account"
                label="账号"
                :placeholder="withdrawTo=='alipay'?'请填写支付宝账号':'请填写微信账号'"
                label-width="80px"
                label-class="text-sm"
            />
          </template>

          <div class="text-xs text-gray-500 mt-2 mb-4 mx-2">
            <template v-if="withdrawTo === 'bank'">
              为保证您的资金顺利提现，请先绑定银行卡账号。
            </template>
            <template v-else>
              为保证您的资金顺利提现，请正确填写微信支付宝账号。
            </template>
          </div>

          <div class="mt-8 mx-2">
            <Button round block type="danger" native-type="submit">
              确定
            </Button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>


import {mapActions} from "vuex";
import {Button, Field, Form, Icon, NavBar, Radio, RadioGroup} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, Button, Field, RadioGroup, Radio, Icon, Form},
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    getCurrentList() {
      return this.withdrawTo === 'bank' ? this.bankList : this.normalList;
    }
  },
  watch: {
    withdrawTo(newValue) {
      if (newValue === 'bank') {
        this.selectIndex = 0;
        this.amount = this.bankList[0].value;
      } else {
        this.selectIndex = 0;
        this.amount = this.normalList[0].value;
      }
    }
  },
  data() {
    return {
      amount: 10,
      withdrawTo: 'wechat',
      name: '',
      account: '',
      normalList: [
        {
          name: "10元",
          value: "10",
        },
        {
          name: "500万",
          value: "5000000",
        },
        {
          name: "1000万",
          value: "10000000",
        },
        {
          name: "5000万",
          value: "50000000",
        },
      ],
      bankList: [
        {
          name: "500万",
          value: "5000000",
        },
        {
          name: "1000万",
          value: "10000000",
        },
        {
          name: "5000万",
          value: "50000000",
        },
      ],
      selectIndex: 0,
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),

    onClickLeft() {
      this.$router.go(-1);
    },
    onChangeIndex(index) {
      this.selectIndex = index;
      this.amount = this.getCurrentList[index].value;
    },
    onSubmit() {
      const {amount,name,account,withdrawTo} = this;
      if (isNullOrEmpty(amount)) {
        this.$toast("请输入提现数额");
        return
      }
      if (withdrawTo !== 'bank') {
        if (isNullOrEmpty(name)) {
          this.$toast("请填写您的真实姓名");
          return
        }
        if (isNullOrEmpty(account)) {
          this.$toast(
            withdrawTo === 'alipay'
              ? "请填写支付宝账号"
              : withdrawTo === 'wechat'
                ? "请填写微信账号"
                : "请填写银行卡号"
          );
          return
        }
      }

      axios.post(api.userWithdrawal, {
        num: amount,
        coin_type: 'invite_award',
        type:withdrawTo === 'alipay'?2:withdrawTo === 'wechat'?3:1,
        name: withdrawTo !== 'bank' ? name : undefined,
        account: withdrawTo !== 'bank' ? account : undefined,
      }).then(() => {
        this.$toast("申请成功，正在审核");
        this.getUserInfo();
        this.onClickLeft();
      }).catch((err) => {

          this.$dialog.alert({
            title: '提示',
            message: err.msg || '提现失败',
          })
              .then(() => {
                if (err.code == 202) {
                  this.$router.push({
                    name: "buyAssets",
                    query: {
                      title: "立即支付",
                      source: 7,
                      price: err.data.price,
                      name: '手续费',
                      id: 2,
                    },
                  });
                }
              })
              .catch(() => {
              });

      });
    },

  },
}
</script>

<style scoped lang="less">
.van-radio__icon {
  display: none;
}

.custom-radio {
  display: flex;
  align-items: center;
  
  .van-radio__label {
    margin-left: 4px;
  }
}

.custom-radio-icon {
  width: 20px;
  height: 20px;
  border: 1px solid #c8c9cc;
  border-radius: 100%;
  box-sizing: border-box;
  position: relative;
}

.custom-radio-icon-checked {
  border-color: #1989fa;
  background-color: #1989fa;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #fff;
    transform: translate(-50%, -50%);
  }
}
</style>
