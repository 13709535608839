<template>
  <div>
    <div class="w-full">
      <img src="@/assets/img/award/banner.png" alt="" class="w-full">
    </div>
    <div class="body-content">
      <LuckyWheel
          ref="myLucky"
          width="300px"
          height="300px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
          :defaultConfig="defaultConfig"
      />
    </div>
    <div class="text-black text-lg  font-bold text-center mt-4">剩余抽奖次数:{{remain}}次</div>
    <div class="flex flex-row justify-between items-center">
      <div class="bg-FEFFED rounded-r-full px-2 py-1 text-base text-red-600" @click="showRule">玩法规则</div>
      <div class="bg-FEFFED rounded-l-full px-2 py-1 text-base text-red-600" @click="jumpToPage('awardRecord')">我的抽奖
      </div>
    </div>
    <div class="flex items-center justify-center">
      <img src="@/assets/img/award/btn.png" style="height: 40px" @click="startCallback">
    </div>

  </div>

</template>
<script>

import {mapActions, mapState} from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {},
  data() {
    return {
      remain: 0,
      blocks: [],
      prizes: [
        {
          background: 'white',
          fonts: [
            {
              text: '红旗H9',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/1.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得一辆红旗H9',
          type: 1,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '商品房',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/2.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得商品房一套',
          type: 2,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '黄金金砖',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/3.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得999.9黄金金砖',
          type: 3,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '谢谢参与',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/4.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '再接再厉，邀请更多好友一起赢奖',
          type: 0,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '黄金戒指',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/6.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得黄金戒指',
          type: 6,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '黄金手链',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/7.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得黄金手链',
          type: 4,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '华为手机',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/8.png'),
            width: '40%',
            height: '40%',
            bottom: '10%'
          }],
          title: '恭喜您获得全新华为MATE60PRO手机一台',
          type: 5,
        },
        {
          background: 'white',
          fonts: [
            {
              text: '谢谢参与',
              top: 60,
              fontSize: 12,
            }
          ],
          imgs: [{
            src: require('@/assets/img/award/4.png'),
            width: '55%',
            height: '55%',
            bottom: '10%'
          }],
          title: '再接再厉，邀请更多好友一起赢奖',
          type: 3,
        },

      ],
      defaultConfig: {
        gutter: 2
      },
      activeStyle: {
        borderRadius: '0px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        background: '#f8b536',
      },
      buttons: [{
        x: 1, y: 1,
        background: 'rgba(0,0,0,0)',
        fonts: [{text: '', top: '70%', fontSize: '10px', color: '#333'}],
        imgs: [{
          src: require('@/assets/img/award/5.png'),
          width: 140,
          height: 140,
          top: -70
        }],
      }],
      isSpinning: false,
      loading: false,
      finished: false,
      refreshing: false,
      isRunning: false,
    };
  },
  mounted() {
    this.getIdentifyInfo();
    this.getDrawNum();
  },
  computed: {
    ...mapState("user", ["identifyInfo"]),
  },
  methods: {
    ...mapActions("user", ["getIdentifyInfo"]),
    showRule() {
      this.$dialog.alert({
        title: '玩法规则',
        message: '邀请好友即可获取抽奖次数' ,
        confirmButtonText: '我知道了',
        theme: 'round-button'

      }).catch(err => {
      });
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      if (isNullOrEmpty(this.identifyInfo)||this.identifyInfo.status!==2) {
        this.$dialog.confirm({
          title: '提示',
          message: '参与抽奖需要实名认证，是否前往实名认证？',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }

      if (this.remain <= 0) {
        this.$dialog.alert({
          title: '提示',
          message: '邀请一人实名认证获取一次抽奖机会',
          theme: 'round-button',
        }).then(() => {
          this.$router.push({name: 'inviteUser'});
        }).catch(() => {
        });
        return;
      }
      if (this.isRunning == true) {
        return;
      }
      this.isRunning = true;
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      axios
          .post(api.luckNum)
          .then((res) => {
            const index = this.prizes.findIndex(item => item.type === res.data.award_id)
            this.$refs.myLucky.stop(index)
          })
          .catch((err) => {
            this.isRunning = false;
          });
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      this.$dialog.alert({
        title: '中奖提示',
        message: prize.title,
        theme: 'round-button',
      }).then(() => {
        this.remain--;
        this.isRunning = false;
        this.getDrawNum();
      });
    },
    jumpToPage(name) {
      this.$router.push({name})
    },
    getDrawNum() {
      axios
          .get(api.drawNum)
          .then((res) => {
            console.log('剩余抽奖次数', res)
            this.remain = res.data.remainJoinNum;
            // this.buttons = [{
            //   x: 1, y: 1,
            //   background: 'rgba(0,0,0,0)',
            //   fonts: [{
            //     text: this.remain > 0 ? `还有${this.remain}次抽奖机会` : '抽奖机会已用完',
            //     top: '70%',
            //     fontSize: '10px',
            //     color: '#333'
            //   }],
            //   imgs: [{
            //     src: require('@/assets/img/award/djcj.png'),
            //     width: '100%',
            //     height: '100%',
            //     bottom: '10%'
            //   }],
            // }];
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style lang="less" scoped>
.body-content {
  background-image: url('../assets/img/award/wheel-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  flex-direction: column;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
