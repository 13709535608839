<template>
  <div class="page-container h-screen page-body">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class=" overflow-hidden w-full  h-full">
      <div class="w-full  items-center">
        <img src="@/assets/img/invite/icon.png" class="w-full">
      </div>
      <div class=" flex justify-center items-center w-full ">
        <div class="bg-white rounded-xl m-4 px-2 py-4 flex flex-row justify-self-center w-full">

          <div>
            <div id="qrcode" class="my-2"></div>
            <div class="text-center text-sm text-gray-500">图片分享给好友一起赚钱</div>
          </div>

          <div class="ml-4">
            <p class="text" style="font-size: 20px"> {{ invitaCode }}</p>
            <p class="text self-center"> 邀请码</p>
            <button @click="onCopyText(invitaCode)" class="w-full bg-red-500 text-white text-base rounded p-2 mt-2" >复制邀请码</button>
            <button @click="onCopyText(url)" class="w-full bg-blue-500 text-white text-base rounded p-2  mt-2">复制推广链接</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import QRCode from "qrcodejs2";
import {NavBar,} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "InviteUser",
  components: {NavBar,},
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      url: '',
    };
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.loadData();
  },

  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    onCopyText(text) {
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功！')
    },
    generateQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        this.url = `${this.$config.WEB_URL}/register?code=${this.invitaCode}`;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 150,
          height: 150
        });
      }
    },
    loadData() {
      // 获取邀请码和推广链接
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const request1 = axios.get(api.userInfo);
      // const request2 = axios.get(api.systemInfo);
      axios.all([request1, ])
          .then(axios.spread((response1, ) => {
            console.log(response1);
            this.invitaCode = response1.data.userInfo.code;
            console.log(this.invitaCode);
            // this.shareUrl = response2.data.share_url;
            this.generateQRCode();
            this.$toast.clear();
          }))
          .catch(error => {
            // 处理错误
            console.error(error);
            this.$toast.clear();
          });
    }
  },


}
</script>

<style scoped lang="less">


.page-container  /deep/ .van-nav-bar .van-icon
{
  color: #ffffff;
}

.page-body {
  background-image: url('../../assets/img/invite/bg_invite.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

</style>
