<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
      <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
        <list
            v-model="loading"
            :finished="finished"
            :finished-text="list.length>10?'没有更多了':''"
            @load="loadMore"
            @refresh="refreshData"
        >
          <div v-for="(item, index) in list" :key="index" class="flex flex-col bg-white p-2 border-line"
               :class="{ 'border-b': index !== list.length - 1 }">

            <div class="flex flex-row  ml-1 flex-1 justify-between">
              <div class="text-sm text-b333 font-bold">{{ item.coin_name}}</div>
              <div class="text-sm " :class="getStatusColorClass(item.status)">{{ getReviewStatusLabel(item.status,item.coin_name,item.pay_type) }}</div>
            </div>
            <div class="flex flex-row ml-1 justify-between my-1">
              <div class="text-sm text-b333">提现:{{ item.num }}元</div>
              <div class="text-sm text-b333">{{ item.created_at | timeFormat('YYYY.MM.DD HH:mm') }}</div>
            </div>
          </div>
        </list>
        <Empty v-if="list.length === 0" description="暂无数据"/>
      </PullRefresh>
    </div>
    </div>
  </div>
</template>
<script>


import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {getNTypeLabel, getReviewStatusLabel, NType} from "@/config";

export default {
  components: { NavBar, List, Empty, PullRefresh,},
  data() {
    return {
      getReviewStatusLabel,
      getNTypeLabel,
      NType,
      title: this.$route.meta.title,
      count: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  filters: {
    getLastFourDigits(value) {
      return value.slice(-4);
    },
  },
  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.count = 0
      this.loadMore()
    },
    loadMore() {

      axios
          .get(api.withdrawalRecordList, {
            params: {
              page: this.pageNo,
              coin_type: "stock",
            }
          })
          .then((res) => {

            if (this.pageNo === 1) {
              console.log(res.data.list);
              this.list = res.data.list;
              this.count = res.data.total;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.loading = false
            this.refreshing = false
            if (this.list.length < this.count) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
    getStatusColorClass(status) {
      switch (status) {
        case 1:
          return 'text-green-500'; // 返回绿色文字样式类
        case 0:
        case 2:
          return 'text-red-500'; // 返回红色文字样式类
        default:
          return 'text-b333'; // 默认文字样式类
      }
    },
  },
};
</script>

<style scoped lang="less">

</style>
