<template>
  <div>
    <canvas ref="idCardCanvas" :width="cardWidth" :height="cardHeight" style="display:none"></canvas>
    <img v-if="generatedCardUrl" :src="generatedCardUrl" class="w-full mt-4" alt="Generated ID Card" />
  </div>
</template>

<script>
import backgroundImage from '@/assets/img/yjsfz/ic_sfz.png'
import {isNullOrEmpty} from "@/utils"; // 假设背景图片存放在这个路径

export default {
  props: {
    cardData: {
      type: Object,
      required: true
      // 预期格式: { name: string, gender: string, birthYear: string, birthMonth: string, birthDay: string, address: string, avatarUrl: string }
    },
    cardWidth: {
      type: Number,
      default: 984
    },
    cardHeight: {
      type: Number,
      default: 622
    }
  },
  data() {
    return {
      generatedCardUrl: null,
      backgroundImage: backgroundImage
    }
  },
  mounted() {
    this.generateIdCard();
  },
  methods: {
    async generateIdCard() {
      const canvas = this.$refs.idCardCanvas;
      const ctx = canvas.getContext('2d');

      // 加载背景图片
      const bgImg = await this.loadImage(this.backgroundImage);
      ctx.drawImage(bgImg, 0, 0, this.cardWidth, this.cardHeight);

      // 设置文字样式
      ctx.font = '36px Arial';
      ctx.fillStyle = 'black';

      // 填写个人信息
      ctx.fillText(`${this.cardData.name||''}`, 190, 130);
      ctx.fillText(`${this.cardData.sex||''}`, 190, 200);
      ctx.fillText(`${this.cardData.year||''}`, 190, 275);
      ctx.fillText(`${this.cardData.month||''}`, 340, 275);
      ctx.fillText(`${this.cardData.day||''}`, 440, 275);
      // 处理地址换行
      this.wrapText(ctx, this.cardData.get_address||'', 190, 356, 500, 40);
      ctx.fillText(`${this.cardData.id_card_no||''}`, 340, 556);

      // // 加载并绘制头像
      if (this.cardData.head_img) {
        const avatarImg = await this.loadImage(this.cardData.head_img);
        ctx.drawImage(avatarImg, 700, 100, 162, 200);
      }

      // 生成最终图片URL
      this.generatedCardUrl = canvas.toDataURL('image/png');
      this.$emit('card-generated', this.generatedCardUrl);
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';  // 添加这行来支持跨域图片
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
        img.src = url;
      });
    },
    wrapText(ctx, text, x, y, maxWidth, lineHeight) {
      if(isNullOrEmpty(text)){
        return;
      }
      const words = text.split('');
      let line = '';
      let yPos = y;

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n];
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && n > 0) {
          ctx.fillText(line, x, yPos);
          line = words[n];
          yPos += lineHeight;
        } else {
          line = testLine;
        }
      }
      ctx.fillText(line, x, yPos);
    }
  },
  watch: {
    cardData: {
      handler() {
        this.generateIdCard();
      },
      deep: true
    }
  }
}
</script>
