<template>
  <div class="page-container h-screen w-screen">
    <nav-bar
        left-text=""
        left-arrow
        :title="title"
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="welfare-list">
          <div class="item">
            <p class="title">{{ content }}</p>
            <p class="title">{{ price }}元</p>
          </div>
        </div>
        <radio-group v-model="selected">
          <cell-group title="支付方式" class="m-3 rounded-b">
            <cell v-for="(item, index) in paymentList"
                  :key="index"
                  clickable
                  :title="item.name"
                  @click="changePayType(item)"
                  v-if="systemInfo[item.is_show]==1"
                  class="flex flex-row items-center"
            >
              <template #icon>
                <img :src="item.icon" class="h-5 mr-1">
              </template>
              <template #right-icon>
                <radio :name="item.id"/>
              </template>
            </cell>
          </cell-group>
        </radio-group>
        <div class="annius_S">
          <button type="button" @click="onPay">立即支付</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Cell, CellGroup, NavBar, Radio, RadioGroup} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

/**
 * 购买 恢复资产页面
 */
export default {
  components: {NavBar, CellGroup, Cell, RadioGroup, Radio},
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
  },
  data() {
    return {
      id: this.$route.query.id,
      content: this.$route.query.name,
      price: this.$route.query.price,
      source: this.$route.query.source || 2,
      title: this.$route.query.title,
      payType: 2,
      channel_type: 1,
      selected: 2,
      paymentList: [
        {
          id: 2,
          name: '支付宝A',
          icon: require('../../assets/img/payment/alipay.png'),
          type: 2,
          is_show: 'alipay_enable',
          channel_type: 1,
        },
        {
          id: 3,
          name: '支付宝B',
          icon: require('../../assets/img/payment/alipay.png'),
          type: 2,
          is_show: 'b_alipay_enable',
          channel_type: 2,
        },
        {
          id: 4,
          name: '微信支付A',
          icon: require('../../assets/img/payment/wxpay.png'),
          type: 1,
          is_show: 'wechat_enable',
          channel_type: 1,
        },
        {
          id: 5,
          name: '微信支付B',
          icon: require('../../assets/img/payment/wxpay.png'),
          type: 1,
          is_show: 'b_wechat_enable',
          channel_type: 2,
        },
        {
          id: 0,
          name: '银联A',
          icon: require('../../assets/img/payment/union.png'),
          type: 4,
          is_show: 'yun_pay_enable',
          channel_type: 1,
        },
        {
          id: 1,
          name: '银联B',
          icon: require('../../assets/img/payment/union.png'),
          type: 4,
          is_show: 'b_yun_pay_enable',
          channel_type: 2,
        },
        {
          id: 6,
          name: '云闪付A',
          icon: require('../../assets/img/payment/ysf.png'),
          type: 3,
          is_show: 'yun_pay_enable',
          channel_type: 1,
        },
        {
          id: 7,
          name: '云闪付B',
          icon: require('../../assets/img/payment/ysf.png'),
          type: 3,
          is_show: 'b_yun_pay_enable',
          channel_type: 2,
        },
        {
          id: 8,
          name: '余额支付',
          icon: require('../../assets/img/payment/yue.png'),
          type: 9,
          is_show: 'balance_enable',
        },
      ],
    };
  },
  mounted() {
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    changePayType(item) {
      this.selected = item.id;
      this.payType = item.type;
      this.channel_type = item.channel_type || "A";
    },
    onPay() {
      let params = {
        source: this.source,
        project_id: this.id,
        amount: this.price,
        pay_type: this.payType,
        channel_type: this.channel_type,
      };
      this.$toast.loading({
        message: "支付中...",
        forbidClick: true,
      });
      axios
          .post(api.balancePay, params)
          .then((res) => {
            if (this.payType == 9) {
              this.$toast.clear();
              this.$toast('支付成功！')
              this.getUserInfo();
              this.onClickLeft();
            } else {
              console.log('拉起支付成功', res.data)
              if (res.data?.payUrl) {
                window.location.href = res.data.payUrl;
              }
            }
          })
          .catch((err) => {
            this.$toast.clear();
            err.msg && this.$toast(err.msg);
          });
    },

  },
}
</script>

<style scoped lang="less">

.welfare-list {
  padding: 15px 10px;

  .item {
    background-color: #d3281d;
    border-radius: 12px;
    margin-bottom: 15px;
    padding: 15px 0px;

    .title {
      padding-bottom: 15px;
      color: #fff;
      font-size: 16px;
      font-weight: 550;
      text-align: center;
    }
  }
}

.annius_S {
  text-align: center;
}

.annius_S button {
  background: linear-gradient(to right, #e32429, #e32429);
  border: none;
  margin: 20px auto auto;
  color: #fff;
  border-radius: 40px;
  width: 90%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

</style>
