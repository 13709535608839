<template>
  <div class="h-full w-full bg-buy">
    <div class=" overflow-hidden w-full">
      <div class="page-content-2">
  <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">

    <list
        v-model="loading"
        :finished="finished"
        @load="loadMore"
        @refresh="refreshData"
    >
      <div style="padding-top: 280px"  class="bg-body">
<!--        <div style="padding-top: 260px" class="text-white font-bold text-center text-lg mb-2">数字人民币+五行币理财</div>-->
<!--        <div class="flex flex-row items-center justify-center text-lg text-white mb-4">-->
<!--          <div class="tab-checked text-center  bg-primary text-white  rounded-l-full">数字人民币</div>-->
<!--          <div @click="changePage" class="tab text-center bg-white text-primary rounded-r-full">五行币股权</div>-->
<!--        </div>-->
        <div class="w-full flex flex-row flex-wrap ">
          <div class=" w-1/2 px-2 mb-2"
               v-for="(item,index) in list"
               :key="index">
            <div class="bg-FEF9F1 p-2 flex flex-col rounded bg-white">
              <div class="flex flex-row items-center mb-2">
                <img src="@/assets/img/dividend/szrmb.png" style="height: 20px"/>
                <div class="text-blah font-bold text-base">数字人民币</div>
              </div>
              <div class="bg-FFF3EF text-81511C text-base rounded-md p-2 flex flex-col my-1 flex items-center justify-between">
                <div class="text-primary font-bold text-lg mb-1">赠 {{ item.gift_coin_num }}</div>
                <div >数字人民币</div>
                <div class="text-lg">购买价格 <span class="text-009944">{{ item.price|formatDecimal2 }}元</span></div>
              </div>
              <div class="btn rounded-full py-2 text-white font-bold text-base px-4 text-center"
                   @click="jumpToPay(item)">
                立即激活
              </div>
            </div>
          </div>
        </div>
      </div>
    </list>
    <Empty v-if="isNullOrEmpty(list)&&loading===false&&refreshing==false" description="暂无数据"/>
  </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>
import {Empty, List, PullRefresh} from "vant";
import {isNullOrEmpty} from "@/utils";
import axios from "@/net/axios";
import api from "@/net/api";
import moment from "moment";

export default {
  name: "Fund",
  components: {List, Empty, PullRefresh},
  data() {
    return {
      isNullOrEmpty,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      dateTime: moment().format('YYYY年MM月DD日HH时mm分')
    };
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  filters: {
    formatDecimal(value) {
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      return parseFloat(value / 10000).toFixed(0);
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios.get(api.welfareList,{params: {type: 3}})
          .then(res => {
            this.list = res.data.idoList
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch(err => {
            this.loading = false
            this.finished = true
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToPay(data) {
      this.$router.push({
        name: "buyDividend",
        query: {
          id: data.id,
          stock: data.gift_coin_num+'元',
          price: data.price,
          title: data.title,
          subtitle: '赠数字人民币',
          source:2,
        },
      });
    },
    changePage() {
      this.$router.push({
        name: "debt", // 跳转到我的资产页面
      });
    }
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #B41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-body {

}
.bg-buy{
  background-image: url('../../assets/img/dividend/bg_dividend.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}


.tab {
  width: 126px;
  height: 40px;
  line-height: 40px;
}

.tab-checked {
  width: 126px;
  height: 40px;
  line-height: 40px;
}


.btn {
  background: linear-gradient(90deg, #FF5455, #FFB533);
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

</style>
