<template>
  <div class="my-2 mx-4 flex flex-row flex-nowrap items-center overflow-x-auto">
    <div class="item bg-white flex flex-row rounded-lg p-2"
         v-for="(item,index) in dataList"
         :key="index"
    >
      <img :src="item.icon" alt="" style="height: 100px;" class="mb-1">
      <div class="ml-2">
        <div class="text-primary font-bold text-base mb-1">{{ item.title }}</div>
        <div class="text-gray-400 text-sm mb-1">
          <span class="text-gray-500 mr-1">价值:</span>
          <span class="text-black font-bold">{{ item.value }}万</span>
        </div>
        <div class="text-gray-400 text-sm mb-1">
          <span class="text-gray-500 mr-1">条件:</span>
          <span class="text-black font-bold">推荐{{ item.recommend }}人实名注册可抽奖</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Cell} from "vant";

export default {
  components: {Cell},
  props: ['pageKey'],
  data() {
    return {
      dataList: [
        {
          title: '抽红旗H9',
          value: '50',
          recommend: '50',
          icon: require('@/assets/img/award/1.png'),
        },
        {
          title: '商品房一套',
          value: '80',
          recommend: '40',
          icon: require('@/assets/img/award/2.png'),
        },
        {
          title: '999.9黄金金砖',
          value: '60',
          recommend: '30',
          icon: require('@/assets/img/award/3.png'),
        },
        {
          title: '黄金戒指',
          value: '5',
          recommend: '20',
          icon: require('@/assets/img/award/6.png'),
        },
        {
          title: '黄金手链',
          value: '5',
          recommend: '20',
          icon: require('@/assets/img/award/7.png'),
        },
        {
          title: '华为手机',
          value: '1',
          recommend: '3',
          icon: require('@/assets/img/award/8.png'),
        },
      ]
    };
  },

  mounted() {

  },

  methods: {
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },

  },
};
</script>

<style lang="less" scoped>
.overflow-x-auto {
  overflow-x: auto;
  white-space: nowrap;
}
.item {
  flex: 0 0 90%; /* 每个item的宽度为父容器的100% */
  box-sizing: border-box; /* 包含padding和border在内 */
  margin-right: 10px; /* 右边距为10px */
}
</style>
