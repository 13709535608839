<template>
  <div class="bg-F1E3E5 flex flex-col  rounded-lg">
    <div class="bg-white flex flex-col  rounded-lg p-2">
      <div class="text-base">个人资产</div>
      <div class="withdrawal-container">
        <div class="w-1/2 py-2">
          <div class="flex flex-row items-center">
            <div class="ml-3">
              <div class="text-black text-base text-666666">
                五行币
              </div>
              <div class="text-black font-bold text-base">
                {{ wuxingCoin.num || '0.00' }}
              </div>
              <div class="bg-FFE5E5 text-C71122 text-base rounded-md mt-1 py-1 px-2 text-center"
                   @click="jumpToWithdraw(wuxingCoin,'五行币')"
                   style="width: 80px;">提现
              </div>
            </div>
          </div>
        </div>
        <div class="w-2"></div>
        <div class="w-1/2 py-2">
          <div class="flex flex-row items-center">
            <div class="ml-3">
              <div class="text-black text-base text-666666">
                数字人民币
              </div>
              <div class="text-black font-bold text-base">
                {{ eCnyData.num || '0' }}
              </div>
              <div
                  class="bg-FFE5E5 text-C71122 text-base rounded-md mt-1 py-1 px-2 text-center"
                  @click="jumpToWithdraw(eCnyData,'数字人民币')"
                  style="width: 80px;"
              >提现
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="withdrawal-container2">
        <div class="w-1/2 py-2">
          <div class="flex flex-row items-center">
            <div class="ml-3">
              <div class="text-black text-base text-666666">
                五行天下股权
              </div>
              <div class="text-black font-bold text-base">
                {{ stockData.num || '0' }}
              </div>
              <div class="bg-FFE5E5 text-C71122 text-base rounded-md mt-1 py-1 px-2 text-center"
                   style="width: 80px;"
                   @click="jumpToWithdraw(stockData,'五行天下股权')">提现
              </div>
            </div>
          </div>
        </div>
        <div class="w-2"></div>
        <div class="w-1/2 py-2">
          <div class="flex flex-row items-center">
            <div class="ml-3">
              <div class="text-black text-base text-666666">
                人民币钱包
              </div>
              <div class="text-black font-bold text-base">
                {{ incomeWalletData.num || '0.00' }}
              </div>
              <div class="bg-FFE5E5 text-C71122 text-base rounded-md mt-1 py-1 px-2 text-center"
                   @click="jumpToWithdraw(incomeWalletData,'人民币钱包提现')" style="width: 80px;">提现
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4">
      <div class="flex flex-row items-center text-E65C5C text-base">
        <img src="@/assets/img/profile/ic_rmb.png" style="height: 20px;margin-right:5px;"/>
        <span>恢复资产钱包</span>
      </div>
      <div class="flex flex-row items-center justify-between text-666666 text-base">
        <div class="text-black font-bold text-base">{{ cnyCoin.value||0 }}</div>
        <div class="bg-white text-C71122 text-base rounded-md mt-1 py-1 px-2 text-center"
             style="width: 80px;"
             @click="jumpToWithdraw(cnyCoin,'恢复资产钱包')">提现
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  props: {
    identifyInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      incomeWalletData: {},
      eCnyData: {},
      stockData: {},
      wuxingCoin: {},
      cnyCoin: {},
    };
  },
  mounted() {
    this.getUserWallet();
  },

  methods: {
    getUserWallet() {
      axios.get(api.userWallet)
          .then((res) => {
            // 找到收益钱包的数据对象
            this.incomeWalletData = res.data.coinList.find(item => item.name_en === "invite_award");

            this.eCnyData = res.data.coinList.find(item => item.name_en === "e_cny");

            this.stockData = res.data.coinList.find(item => item.name_en === "stock");

            this.wuxingCoin = res.data.coinList.find(item => item.name_en === "wuxing_coin");

            this.eCnyBalance = res.data.coinList.find(item => item.name_en === "e_cny").value;

            this.stockBalance = res.data.coinList.find(item => item.name_en === "stock").value;

            this.cnyCoin = res.data.coinList.find(item => item.name_en === "cny");
          })
          .catch((err) => {
            console.log(err);
          });
    },
    jumpToWithdraw(data, title) {
      if (data.name_en == 'invite_award') {
        this.$router.push({
          name: "withdrawMoney",
        });
        return
      }
      if (isNullOrEmpty(this.identifyInfo) || this.identifyInfo.status != 2) {
        this.$dialog.alert({
          title: '提示',
          message: '请先去实名认证',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      if (data.name_en == 'wuxing_coin'&& this.eCnyData.is_buy == 0) {
        this.$dialog.alert({
          title: '提示',
          message: '必须购买数字人民币才有资格恢复五行资产',
          theme: 'round-button',
          confirmButtonText: '立即激活',
        }).then(() => {
          this.$bus.$emit('active', 3);
          this.$router.push({
            name: "fund",
          });
        });
        return
      }
      if (data.name_en == 'cny'&& this.cnyCoin.is_buy == 0) {
        this.$dialog.alert({
          title: '提示',
          message: '先恢复五行资产',
          theme: 'round-button',
          confirmButtonText: '立即激活',
        }).then(() => {
          this.$bus.$emit('active', 3);
          this.$router.push({
            name: "recover",
          });
        });
        return
      }

      // if (data.name_en == 'e_cny'&&this.stockData.is_buy == 0){
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '提现数字人民币必须激活股权',
      //     theme: 'round-button',
      // }).then(() => {
      //     this.$bus.$emit('active', 2);
      //     this.$router.push({
      //       name: "debt",
      //     });
      //   });
      //   return
      // }
      // console.log('点击提现', data)
      if(data.name_en == 'cny'&&this.eCnyData.is_buy==0)
      {
        // this.$dialog.alert({
        //   title: '提示',
        //   message: '8月15号即可恢复五行天下资产',
        //   theme: 'round-button',
        // }).then(() => {
        //
        // });
        // return
        this.$dialog.alert({
          title: '提示',
          message: '激活数字人民币即可免费提现',
          theme: 'round-button',
          confirmButtonText: '立即激活',
        }).then(() => {
          this.$bus.$emit('active', 3);
          this.$router.push({
            name: "fund",
          });
        });
        return
      }
      // if ( data.name_en == 'e_cny' ) {
      //     this.$dialog.alert({
      //       title: '提示',
      //       message: '8月22号可提现',
      //       theme: 'round-button',
      //     }).then(() => {
      //
      //     });
      //   return
      //
      // }

      if (data.name_en == 'stock') {
        //   if (data.name_en == 'wuxing_coin'){
        //     console.log('点击提现五行币')
        //     this.$dialog.alert({
        //       title: '提示',
        //       message: '8月1号建军节正式开网开放提现五行币，请提前准备好您的五行币。',
        //       theme: 'round-button',
        //     }).then(() => {
        //
        //     });
        //   }else
          this.$dialog.alert({
            title: '提示',
            message: '等待官方通知',
            theme: 'round-button',
          }).then(() => {

          });
        return

      }

      console.log('点击提现', data)
      this.$router.push({
        name: "withdrawal",
        query: {...data, title},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawal-container {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #898989;
  font-size: 14px;

  /deep/ span {
    color: #FF0000;
  }
}

.withdrawal-container2 {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: #898989;
  font-size: 14px;

  /deep/ span {
    color: #FF0000;
  }
}

.btn-primary {
  font-family: MiSans;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: #FFFFFF;
  background-color: #FDAA01;
  border-radius: 5px;
  padding: 5px 10px;
}

.item {
  width: 100%;
  border: 1px solid #FFD6D6;
  border-radius: 12.5px;
  padding: 10px 0;
}
</style>
