<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
      <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
        <div class="bg-primary text-white flex flex-row justify-evenly items-center px-4 py-2 m-2 rounded-md"
        >

          <div class="flex flex-col flex-1 items-center">
            <p class="text-white">{{ childCount.totalAchievement ? childCount.totalAchievement : '0.00' }}元</p>
            <div class="flex flex-row items-center">
              <i class="iconfont icon-zongyeji text-white mr-1"/>
              <p class="text-sm">总业绩</p>
            </div>

          </div>
          <div class="flex flex-col flex-1 items-center" @click="jumpToLevel(1)">
            <p class="text-white">{{ childCount ? childCount.zhi_num : '0' }}人</p>
            <div class="flex flex-row items-center">
              <i class="iconfont icon-next text-white mr-1"/>
              <p class="text-sm">一级</p>
            </div>
          </div>
          <div class="flex flex-col flex-1 items-center" @click="jumpToLevel(2)">
            <p class="text-white">{{ childCount ? childCount.recommendTwoLevelNum : '0' }}人</p>
            <div class="flex flex-row items-center ">
              <i class="iconfont icon-next text-white mr-1"/>
              <p class="text-sm">二级</p>
            </div>
          </div>
          <div class="flex flex-col flex-1 items-center" @click="jumpToLevel(3)">
            <p class="text-white">{{ childCount ? childCount.recommendThreeLevelNum : '0' }}人</p>
            <div class="flex flex-row items-center ">
              <i class="iconfont icon-next text-white mr-1"/>
              <p class="text-sm">三级</p>
            </div>
          </div>
        </div>
        <list
            v-model="loading"
            :finished="finished"
            :finished-text="list.length>10?'没有更多了':''"
            @load="loadMore"
            @refresh="refreshData"
        >
          <div v-for="(item, index) in list" :key="index" class="flex flex-row items-center bg-white p-2 border-line"
               :class="{ 'border-b': index !== list.length - 1 }">
            <van-image
                round
                width="42px"
                height="42px"
                :src="item.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
            />
            <div class="flex flex-col ml-1 flex-1">
              <div class="text-base font-bold text-b333">{{ item.real_name ? item.real_name : '未实名' }}</div>
              <div class="text-sm text-b666">{{ item.username }}</div>
            </div>
            <div class="flex flex-col ml-1 items-end">
              <div class="text-sm text-b333"><span>业绩</span>:<span
                  class="text-red-500"> {{ item.achievement ? item.achievement : '0.00' }}</span></div>
              <div class="text-sm text-b666">加入:{{ item.created_at | timeFormat('YYYY.MM.DD') }}</div>
            </div>
          </div>
        </list>
        <Empty v-if="list.length === 0" description="暂无数据"/>
      </PullRefresh>
    </div>
    </div>
  </div>
</template>

<script>


import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";
import {Empty, Image as VanImage, List, NavBar, PullRefresh,} from "vant";

export default {
  name: "index",
  components: {NavBar, List, VanImage, Empty, PullRefresh},
  data() {
    return {
      count: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      childCount: {},
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.getUserInfo();
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        console.log("userInfo", newVal, oldVal)
        console.log("getTeamStatistics", newVal.isCaptain !== oldVal?.isCaptain)
        if (newVal.isCaptain !== oldVal?.isCaptain) {

          this.getTeamStatistics()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    jumpToLevel(level) {
      this.$router.push({name: 'childTeam', params: {level: level}})
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.count = 0
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.teamList, {
            params: {
              page: this.pageNo,
              size: this.pageSize,
            }
          })
          .then((res) => {

            if (this.pageNo === 1) {
              this.list = res.data.list;
              this.count = res.data.total;
              this.childCount =res.data.userInfo;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.loading = false
            this.refreshing = false
            if (this.list.length < this.count) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>

<style scoped lang="less">
</style>
