<template>
  <div class="h-full w-full page-content-bg bg-contain">
    <nav-bar
        left-text=""
        :left-arrow="true"
        :border="false"
        :safe-area-inset-top="true"
        @click-left="onClickLeft"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content h-full" >
        <Award/>
        <div class="flex flex-row items-center mb-2 mt-4 mx-4">
          <div class="line"></div>
          <div class="text-T010101 text-center font-bold text-lg ">抽奖奖池产品</div>
        </div>
        <ProductList/>
      </div>
    </div>
  </div>
</template>

<script>


import {NavBar,} from "vant";
import Award from "@/components/Award";
import ProductList from "@/components/ProductList";

export default {
  name: "index",
  components: {NavBar,Award,ProductList},
  mounted() {

  },
  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar{
  background-color: transparent;
}
.van-nav-bar /deep/ .van-nav-bar__title{
  color: #fff;
}
.page-content-bg /deep/ .van-nav-bar .van-icon{
  color: #fff;
}
.page-content-bg{
  background-image: url('../../assets/img/index/bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.remain-box{
  background-image: url('../../assets/img/award/top-award.png');
  background-size: 100% 60px;
  background-repeat: no-repeat;
  width: 100%;
  height: 60px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding-bottom: 2px;
}
.body-content {
  background-image: url('../../assets/img/award/bg-arward.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  flex-direction: column;
  padding:30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-animation {
  position: relative;
}

.light-source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: yellow;
  opacity: 0.5;
  z-index: 1;
  transition: transform 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes spin {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

</style>
