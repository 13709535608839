<template>
  <div class="h-full w-full bg-home">
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <div class="flex flex-row items-center justify-between p-2">
          <img src="@/assets/img/index/logo.png" alt="" style="width: 60px;">
          <div class="flex flex-col items-center justify-center" @click="jumpToPage('customService')">
            <img src="@/assets/img/index/kf.png" alt="" style="width: 30px;">
            <div class="text-white text-sm">客服</div>
          </div>
        </div>
        <Banner></Banner>
        <div class="px-4" @click="jumpToPage('award')">
          <img src="@/assets/img/index/cj.png" class="w-full">
        </div>

        <div class="flex flex-row items-center mb-2 mt-4 mx-4">
          <div class="line"></div>
          <div class="text-T010101 text-center font-bold text-lg ">五行天下张健董事长实名认证</div>
        </div>
        <div class="px-4">
          <img src="@/assets/img/index/sfz.png" alt="" class="w-full mb-2">
          <img src="@/assets/img/index/sfz_1.png" alt="" class="w-full mb-2">
          <HomeVideo/>
        </div>
<!--        <div class="flex flex-row items-center mb-2 mt-4 mx-4">-->
<!--          <div class="line"></div>-->
<!--          <div class="text-T010101 text-center font-bold text-lg ">新闻资讯</div>-->
<!--        </div>-->
<!--        <News></News>-->
      </div>
    </div>
    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="problem" class="text-[16px]"></p>

        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(1)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog2" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="introduce" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(2)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog3" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="home_show" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(3)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import Banner from "@/components/home/Banner";
import {mapActions, mapState} from "vuex";
import {isNullOrEmpty} from "@/utils";
import {Popup,} from "vant";
import HomeVideo from "@/components/home/HomeVideo";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "index",
  components: {Banner, Popup, HomeVideo},
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      console.log('title', this.$route.meta.title)
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      problem: null,
      introduce: null,
      home_show: null,

    };
  },
  created() {
    // 从本地存储中获取 this.problem 的值
    const savedProblem = sessionStorage.getItem('problem');
    if (savedProblem) {
      this.problem = savedProblem;
    }
  },
  beforeDestroy() {
    // 在组件销毁之前将 this.problem 的值保存到本地存储
    sessionStorage.setItem('problem', this.problem);
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getNoticeList();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    getNoticeList: function () {
      axios
          .get(api.newsList,{params: {type: 1,index_status:1}})
          .then((res) => {
            console.log('公告列表',res)
            if(res.data.length>0){
              this.problem = res.data[0].content;
              if(res.data.length>1){
              this.introduce = res.data[1].content;
              }
              if(res.data.length>2){
              this.home_show = res.data[2].content;
              }
              this.dialog = true;
            }
          })
          .catch(() => {

          });
    },
    closeDialog(type) {
      if (type === 1) {
        this.dialog = false;
        if (!isNullOrEmpty(this.introduce)) {
          this.dialog2 = true;
        }
      } else if(type === 2) {
        this.dialog2 = false;
        if (!isNullOrEmpty(this.home_show)) {
          this.dialog3 = true;
        }
      }else {
        this.dialog3 = false;
      }
    },
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },
  },

};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}

//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}
.bg-home {
  background-image: url('../../assets/img/index/bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.line {
  width: 5px;
  height: 20px;
  background: #FF0820;
  border-radius: 5px;
  margin-right: 10px;
}

.notice-title {
  width: 320px;
  height: 67px;
}


</style>
