<template>

  <div class="w-full px-4 py-2" v-if="adList.length">
    <swipe
        :autoplay="3000"
        indicator-color="white"
    >
      <swipe-item
          v-for="(item,index) in adList"
          :key="index"
      >
        <img
            v-lazy="item.banner_url"
            class="rounded-md"
        />
      </swipe-item>
    </swipe>
  </div>
</template>

<script>
import axios from "../../net/axios";
import api from "../../net/api";
import {Swipe, SwipeItem} from "vant";

export default {
  name: "Banner",
  components: {Swipe, SwipeItem},
  props: {},
  data() {
    return {
      adList: [],
    };
  },
  mounted: function () {
    this.getAdList();
  },
  methods: {
    getAdList: function () {
      axios
          .get(api.getBanner)
          .then((res) => {
            this.adList = res.data.bannerList;
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>
<style scoped lang="less">

</style>
