<template>
  <div class="page-container h-screen w-screen">
    <nav-bar
        left-text=""
        left-arrow
        :title="title"
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full p-2">
        <div class="text-white text-base mb-2">*请核对好转账信息确保信息填写正确。</div>
        <div class="bg-white rounded p-2">
          <div class="bg-FFF8F2  p-2">
            订单编号: 202407071223466890<br/>
            付款金额: <span style="color: red">{{query.amount}}</span><br/>
            收款姓名: {{systemInfo.bankname}}<br/>
            开户银行: {{systemInfo.openbank}}<br/>
            银行账户: {{systemInfo.banknum}}
          </div>
          <div class=" text-base mb-2 mt-3">上传支付截图<span style="color: red">（必填）</span></div>
          <div class="bg-FFF8F2  p-2">
            <field
                v-model="realname"
                name="realname"
                label="真实姓名"
                placeholder="请输入付款人真实姓名"
                :center="true"
                input-align="right"
            />
            <field
                v-model="bankname"
                name="bankname"
                label="银行名称"
                placeholder="请输入付款人银行名称"
                :center="true"
                input-align="right"
            />
            <field
                v-model="bankcard"
                type="number"
                name="bankcard"
                label="银行卡号"
                placeholder="请输入付款人银行卡号"
                :center="true"
                input-align="right"
            />
            <uploader :after-read="uploadAvatar" class="mt-2">
              <img style="height: 80px"
                   :src="avatar?avatar:require('@/assets/img/dividend/upload.png')">
            </uploader>
          </div>
        </div>
        <div class="flex justify-center items-center mt-3">
          <img src="@/assets/img/dividend/btn.png" class="w-full" @click="onSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 购买分红股
 */
import {mapActions, mapState} from "vuex";
import {Uploader, NavBar,Field} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, Uploader,Field},
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  filters: {
    formatDecimal(value) {
      if (isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      if (isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      if (isNullOrEmpty(value))
        return '';
      return (parseFloat(value) / 10000).toFixed(0) + '万';
    }
  },
  data() {
    return {
      query: this.$route.query,
      avatar: "",
      realname:  '',
      bankname:  '',
      bankcard:  '',
    };
  },
  mounted() {
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    uploadAvatar(file) {
      this.avatar = file.content;
    },

    onSubmit(){
      if(isNullOrEmpty(this.realname))
      {
        this.$toast('请输入付款人真实姓名！');
        return;
      }
      if(isNullOrEmpty(this.bankname))
      {
        this.$toast('请输入付款人银行名称！');
        return;
      }
      if(isNullOrEmpty(this.bankcard))
      {
        this.$toast('请输入付款人银行卡号！');
        return;
      }
      if(isNullOrEmpty(this.avatar))
      {
        this.$toast('请上传支付截图！');
        return;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
        axios.post(api.uploadPaymentImg, {
          order_sn:  this.query.order_sn,
          realname:  this.realname,
          bankname:  this.bankname,
          bankcard:  this.bankcard,
          certificate:  this.avatar,
        })
            .then((res) => {
               this.$toast.clear();
               this.$toast('提交成功！')
               this.$router.push({name: 'dividend'});
            })
            .catch((e) => {
              this.$toast.clear();
              e.msg && this.$toast(e.msg);
            })
    },
  },
}
</script>

<style scoped lang="less">
.page-container {
  background-image: url("../../assets/img/dividend/bg_bankpay.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.welfare-list {
  padding: 15px 10px;
}

.welfare-list .item {
  background-color: #d3281d;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 15px 0px;
}

.welfare-list .item .title {
  padding-bottom: 15px;
  color: #1a1d27;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
}

.welfare-list .item .ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.welfare-list .item .ul li {
  width: 33%;
}

.welfare-list .item .ul li + li {
  border-left: 1px solid #ffdeb6;
}

.welfare-list .item .pre {
  color: #FDE0B4;
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 6px;
}


.welfare-list .item p {
  font-size: 14px;
  color: #7a726a;
}

.annius_S {
  text-align: center;
}

.annius_S button {
  background: linear-gradient(to right, #e32429, #e32429);
  border: none;
  margin: 20px auto auto;
  color: #fff;
  border-radius: 40px;
  width: 90%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

</style>
