<template>
  <div class="h-full w-full flex flex-col bg-FF6040">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              @load="loadMore"
              @refresh="refreshData"
              :finished="finished"
          >
            <div class="bg-page">
            <div v-for="(card, index) in list" :key="index" class=" p-4">
              <img :src="images[index]" alt="" class="w-full">
              <div class="" style="color:navajowhite">开通会员，即可享受更多权益</div>
              <Button type="danger" round block class="mt-2" @click="jumpTo(card)">立即升级</Button>
            </div>
          </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>


import {Button, Empty, List, NavBar, PullRefresh,} from "vant";
import {mapState} from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  components: {Button, NavBar, List, Empty, PullRefresh,},
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      images: [
        require('@/assets/img/member/0.png'),
        require('@/assets/img/member/1.png'),
        require('@/assets/img/member/2.png'),
        require('@/assets/img/member/3.png'),
        require('@/assets/img/member/4.png'),
      ]
    };
  },

  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios.get(api.welfareList, {params: {type: 4}})
          .then(res => {
            console.log('会员列表', res)
            this.list = res.data.idoList
            this.loading = false
            this.finished = true
            this.refreshing = false
          })
          .catch(err => {
            this.loading = false
            this.finished = true
            this.refreshing = false
          });
    },
    jumpTo(item) {
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source: 2,
          price: item.price,
          name: '会员费',
          id: item.id,
        },
      });
    }
  },
};
</script>

<style scoped lang="less">
.bg-page {
  background-image: url('../../assets/img/member/header.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 180px;
}
</style>
