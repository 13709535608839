

export default {
  login: "/api/auth/login", //登录
  register: "/api/auth/register", //注册
  navList: "/api/navigation/list", //菜单
  getBanner: "/api/index/banner", //获取banner
  signIn: "/api/user/sign", //用户签到
  systemInfo: "/api/index/configInfo", //获取系统信息
  userInfo: "/api/user/userInfo", //获取用户信息
  realAuth: "/api/user/identify", //实名认证
  identifyInfo:"/api/user/identifyLog",//实名认证信息获取
  bindBankCard: "/api/user/addBank", //添加或修改银行卡
  bankCardInfo:"/api/user/myBank",//获取银行卡信息
  teamList: "/api/user/teamList", //团队列表
  uploadAvatar: "/api/user/updateHeadImg", //上传头像
  uploadImage: "/api/basic/upload", //上传图片
  balanceLog: "/api/assets/moneyRecord", //资金明细
  newsList: "/api/index/notice", //新闻列表
  newsDetails: "/api/index/noticeDetail", //新闻详情
  userWallet: "/api/assets/myAssets", //我的资产
  drawNum: "/api/turntable/prizes", //剩余抽奖次数
  luckNum: "/api/turntable/join", //抽奖
  drawRecord: "/api/turntable/joinLog", //抽奖记录
  awardAddress:"/api/turntable/editOrderAddress",//奖品地址
  userWithdrawal: "/api/assets/withdraw", //用户提现
  withdrawalRecordList: "/api/assets/withdrawLog", //提现记录
  myHouse: "/api/user/my-house", //我的房产
  receiveList: "/api/user/myInviteAward", //我的邀请奖励数据
  receiveAward: "/api/user/getInviteAward", //领取奖励
  receiveNote: "/api/user/receive-note", //领取本票
  cardAddressList: "/api/user/card-address-list ", //卡包
  welfareList: "/api/ido/idoList", //项目列表
  balancePay: "/api/pay/pay", //支付
  rechargePay: "/api/ido/joinIdo", //余额支付
  buyBonusList: "/api/ido/myInvestList", //项目列表
  reviceReg: "/api/user/receive-reg", //注册领取奖励
  addressList: "/api/address/list ", //地址列表
  addressDetail: "/api/address/detail", //地址详情
  addAddress: "/api/address/add", //添加地址
  editAddress: "/api/address/edit", //编辑地址
  deleteAddress: "/api/address/del", //删除地址
  modifyPwd: "/api/auth/passwordByIdCardNo", //修改密码
  siteCode: "/site/code", //验证码
  addOrder: "/api/pay/add-order", //生成
  uploadPaymentImg: "/api/pay/payment", //上传支付凭证
  myBankOrder: "/api/user/myBankOrder", //我的银行卡订单
  editBankOrder: "/api/user/editBankOrder", //修改银行卡订单收货信息
  updateTradePassword: "/api/user/updateTradePassword", //添加取款密码
  identifyHeadImg: "/api/user/identifyHeadImg", //上传身份证头像
  editIdentity: "/api/user/editIdentity", //云身份证增加邮寄地址


};
