<template>

  <div class="login_box relative">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />

    <div class="p-8 bg-white rounded-lg shadow-lg m-4 " >
      <div class="space-y-4">
        <div class="flex items-center border-b border-gray-300 py-2">
          <i class="iconfont icon-iconfontshouji text-gray-400 mr-2"/>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="account"
                 maxlength="11"
                 placeholder="请输入手机号" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="contact"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="id_card_no"
                 maxlength="18"
                 placeholder="请输入身份证号码" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password"
                 placeholder="请输入新密码" type="password">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="re_password"
                 placeholder="重新请输入密码" type="password">
        </div>
      </div>
      <button class="w-full bg-primary text-white py-2 rounded mt-10" @click="confChange">确认修改</button>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import {Icon, NavBar} from "vant";


export default {
  name: "editPassword",
  components: {NavBar, Icon},
  data() {
    return {
      account: "",
      password: "",
      re_password: "",
      key: new Date().getTime(),
      id_card_no: "",
      title: this.$route.meta.title,
    };
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    confChange() {
      if (isNullOrEmpty(this.account)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      if (isNullOrEmpty(this.re_password)) {
        this.$toast("请再次输入密码");
        return;
      }
      if (this.password !== this.re_password) {
        this.$toast("两次密码输入不一致");
        return;
      }

      if (isNullOrEmpty(this.id_card_no)) {
        this.$toast("请输入身份证号码");//120102198710319350
        return;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });

      const formData = new FormData();
      formData.append('account', this.account);
      formData.append('password', this.password);
      formData.append('re_password', this.re_password);
      formData.append('id_card_no', this.id_card_no);
      axios
          .post(api.modifyPwd, formData)
          .then((res) => {
            this.$toast.clear();
            this.$toast("修改成功");
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },


  },
};
</script>
<style scoped lang="less">
.login_box {
  background-image: url('../../assets/img/login/bg_login.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;

  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    font-size: 24px;
    font-weight: 550;
    color: #fff;
  }

  .van-nav-bar {
    background-color: transparent;
  }
}
</style>
