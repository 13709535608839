<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="text-sm text-red-500  mx-2 my-4">为了您的资金安全，核实银行卡，姓名，银行卡信息，一旦填实，不能自行修改！</div>
        <form @submit="onSubmit" class="rounded-sm bg-white p-2 m-2">
          <field
              v-model="realname"
              name="realname"
              label="真实姓名"
              placeholder="请输入真实姓名"
              :center="true"
              input-align="right"
              :readonly="true"
          />
          <field
              v-model="bankname"
              name="bankname"
              label="银行名称"
              placeholder="请输入银行名称"
              :center="true"
              input-align="right"
              :readonly="isBind"
          />
          <field
              v-model="bankcode"
              type="number"
              name="bankcode"
              label="银行卡号"
              placeholder="请输入银行卡号"
              :center="true"
              input-align="right"
              :readonly="isBind"
          />
          <div class="mt-8 mb-2 mx-2" v-if="!isBind">
            <Button round block type="info" color="#FF5848" native-type="submit">立即绑定</Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, Field, NavBar} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: { NavBar, Button,  Field},
  computed: {
    ...mapState("user", ["bankCardInfo","identifyInfo"]),
  },
  watch: {
    identifyInfo(newVal, oldVal) {
      // console.log('userInfo',newVal,oldVal)

      if(newVal){
        this.realname = newVal.name;
      }
    },
    bankCardInfo(newVal, oldVal) {
      // console.log('userInfo',newVal,oldVal)
      if(newVal){
        this.enableEdit = (newVal.status!==1||newVal.status!==2);
        this.bankname = newVal.bank_name;
        this.bankcode=  newVal.bank_no;
      }
    },
  },
  mounted() {
    this.getIdentifyInfo();
    this.getBankCardInfo();
  },
  data() {
    return {
      title: this.$route.meta.title,
      WithdrawalType,
      bankname: "",
      realname: "",
      bankcode: "",
      isBind: false,
    }
  },
  methods: {
    ...mapActions("user", ["getBankCardInfo","getIdentifyInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      const {bankname, realname,bankcode} = this;
      // if (isNullOrEmpty(realname)) {
      //   this.$toast("请输入真实姓名");
      //   return
      // }
      // const regName = /^[\u4e00-\u9fa5]+·[\u4e00-\u9fa5]+$/;
      // const regName1 = /^([\u4E00-\u9FA5])*$/;
      // if (!regName.test(this.realname) && !regName1.test(this.realname)) {
      //   this.$toast('真实姓名填写有误!')
      //   return;
      //
      // }

      if (isNullOrEmpty(bankname)) {
        this.$toast("请输入银行名称");
        return
      }
      const regBankName =/^[\u4e00-\u9fa5]{2,20}$/;
      if(!regBankName.test(this.bankname)){
        this.$toast('银行名称填写有误!')
        return;
      }
      if (isNullOrEmpty(bankcode)) {
        this.$toast("请输入银行卡号");
        return
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.bindBankCard, {
        bank_name:bankname,
        real_name:realname,
        bank_no:bankcode,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("银行卡绑定成功");
        this.getBankCardInfo();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        err.msg && this.$toast(err.msg);
      });
    },
  },

}
</script>

<style scoped lang="less">

</style>
