<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              @load="loadMore"
              @refresh="refreshData"
              :finished="finished"
          >
            <div v-for="(item, index) in list" :key="index" class="flex flex-col bg-white p-2 py-3 border-line"
                 :class="{ 'border-b': index !== list.length - 1 }">
              <div class="flex flex-row ml-1 justify-between" @click="ToAwardAddress(item)">
                <div class="text-sm text-b666">
                  <img :src="item.imgUrl? item.imgUrl : require('@/assets/img/award/4.png')" style="height: 50px;">
                </div>
                <div class="text-lg text-b333 flex flex-col">
                  <div v-if="item.prize_type==1" class=" text-g999 justify-end flex flex-row justify-end">未中奖</div>
                  <div v-else class="justify-end flex flex-row justify-end"><span class="font-bold ">{{
                      item.name
                    }}</span></div>
<!--                  <div v-if="item.get_address==null&&item.get_name==null&&item.get_mobile==null">-->
<!--                    点击添加收货地址-->
<!--                  </div>-->
<!--                  <div v-else-if="item.get_address!=null&&item.get_name!=null&&item.get_mobile!=null">-->
<!--                    点击修改收货地址-->
<!--                  </div>-->
                  <div>{{ item.created_at | timeFormat('MM月DD日 HH:mm') }}</div>
                </div>
              </div>
              <div class="flex flex-row justify-between text-red-600" v-if="item.name!='谢谢惠顾'">速速去签署保密协议！</div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>


import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, List, Empty, PullRefresh,},
  data() {
    return {
      query: {

      },
      bankCardInfo: {},
      title: this.$route.meta.title,
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      prizes: [
        // {
        //   src: require('@/assets/img/award/4.png'),
        //   type: 0,
        // },
        {
          src: require('@/assets/img/award/1.png'),
          type: 1,
        },
        {
          src: require('@/assets/img/award/2.png'),
          type: 2,
        },
        {
          src: require('@/assets/img/award/3.png'),
          type: 3,
        },
        {
          src: require('@/assets/img/award/7.png'),
          type: 4,
        },
        {
          src: require('@/assets/img/award/8.png'),
          type: 5,
        },
        {
          src: require('@/assets/img/award/6.png'),
          type: 6,
        },
      ],
    };
  },
  filters: {
    getLastFourDigits(value) {
      return value.slice(-4);
    },
  },
  mounted() {
    this.getBankCardInfo();
  },

  methods: {
    getBankCardInfo() {
      axios.get(api.myBankOrder)
          .then((res) => {
            this.bankCardInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    ToAwardAddress(){
      this.$router.push({
        name: "bankCardAddress",
        query: {
          id: this.bankCardInfo.id,
          get_name: this.bankCardInfo.get_name,
          get_mobile: this.bankCardInfo.get_mobile,
          get_address: this.bankCardInfo.get_address,
        },
      });
    },
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.drawRecord)
          .then((res) => {
            if (!isNullOrEmpty(res.data)) {
              this.list = res.data.list.map(item => {
                item.imgUrl = this.prizes.find(prize => prize.type === item.award_id)?.src
                return item
              })
            }
            this.loading = false
            this.finished = true
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true
          });
    },
  },
};
</script>

<style scoped lang="less">

</style>
