<template>
  <div class="page-container h-screen bg-white">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="w-full p-2 relative">
          <img src="@/assets/img/profile/bg_udun.png" class="w-full rounded-sm">
        </div>

        <div v-if="bankCardInfo.status ==1"
             class="bg-red-600 p-2 mx-2 my-4 rounded-full text-white text-center " @click="setPwd">激活U盾
        </div>
<!--        <div v-else-if="bankCardInfo.status ==2&&bankCardInfo.expressMoney ==0"-->
<!--             class="bg-red-600 p-2 mx-2 my-4 rounded-full text-white text-center " @click="showActionSheet=true">申请发货-->
<!--        </div>-->
<!--        <div v-if="bankCardInfo.status ==3&&bankCardInfo.expressMoney !=1500"-->
<!--             class="bg-red-600 p-2 mx-2 my-4 rounded-full text-white text-center " @click="showActionSheet=true">已激活申请发货-->
<!--        </div>-->
<!--        <div v-if="bankCardInfo.status ==3&&bankCardInfo.expressMoney ==1500"-->
<!--             class="bg-red-600 p-2 mx-2 my-4 rounded-full text-white text-center ">已激活申请发货-->
<!--        </div>-->
<!--        <div v-if="(bankCardInfo.status ==2||bankCardInfo.status ==3)" class="bg-red-600 p-2 mx-2 my-4 rounded-full text-white text-center " @click="setPwd">-->
<!--          {{ bankCardInfo.is_password == 0 ? '免费设置取款密码' : '修改取款密码' }}-->
<!--        </div>-->
        <div class=" mt-0">
          <div v-if="bankCardInfo.get_name" class="bg-gray-200 p-4 m-2 rounded-2xl " @click.stop="ToMyAddress">
            <div class="mb-4 flex justify-between items-center">
              <div class=" text-xl font-semibold">配送信息</div>
              <div class=" text-red-500 cursor-pointer  " @click.stop="ToMyAddress">修改</div>
            </div>
            <div class="flex flex-row justify-between items-center">
              <div class="mb-1">姓名：{{ bankCardInfo.get_name }}</div>
              <div class="mr-10">电话：{{ bankCardInfo.get_mobile }}</div>
            </div>
            <div class="mb-1">地址：{{ bankCardInfo.get_address }}</div>
            <div v-if="bankCardInfo.status ==3">制卡进度：<span class="text-blue-500">登记完毕，等待邮寄员上门揽件</span></div>
          </div>
          <div v-else class="bg-gray-200 p-4 m-2 rounded-2xl " @click="ToMyAddress">
            <div class="mb-4 text-xl font-semibold">配送信息</div>
            <div class="mb-4 text-base text-center">点击完善配送信息</div>
          </div>
        </div>

      </div>
    </div>
    <action-sheet v-model="showActionSheet" :actions="actions" @select="onSelect"/>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {NavBar, ActionSheet} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";


export default {
  components: {NavBar, ActionSheet},
  computed: {
    ...mapState("user", ["userInfo", "identifyInfo", "userWallet"]),

  },

  mounted() {
    this.getIdentifyInfo();
    this.getUserInfo();
    this.getUserWallet();
    this.getBankCardInfo();
  },
  data() {
    return {
      title: this.$route.meta.title,
      e_cnyBankNum: 0,
      is_buy: 0,
      bankCardInfo: {},
      showActionSheet: false,
      actions: [],
    }
  },
  methods: {
    isNullOrEmpty,
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo", "getUserWallet"]),
    ToMyAddress() {
      this.$router.push({
        name: "bankCardAddress",
        query: {
          id: this.bankCardInfo.id,
          get_name: this.bankCardInfo.get_name,
          get_mobile: this.bankCardInfo.get_mobile,
          get_address: this.bankCardInfo.get_address,
        },
      });
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showActionSheet = false;
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source:5,
          price:item.price,
          name:'邮寄费',
          id:2,
        },
      });
    },
    mailDetails() {
      this.$router.push({name: "mailProgress"});
    },
    getBankCardInfo() {
      axios.get(api.myBankOrder, {params: {type: 4}})
          .then((res) => {
            this.bankCardInfo = res.data;
            this.updateActions();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    updateActions() {
      const allActions = [
        { name: '普通发货300元-30天内到货', price: 300 },
        { name: '加急发货600元-20天内到货', price: 600 },
        { name: '特急发货1500元-7天内到货', price: 1500 }
      ];
      const expressMoney = this.bankCardInfo.expressMoney||0;
      switch (parseInt(expressMoney)) {
        case 0:
          this.actions = allActions;
          break;
        case 300:
          this.actions = allActions.slice(1);
          break;
        case 600:
          this.actions = allActions.slice(2);
          break;
        default:
          this.actions = [];
      }
    },
    getUserWallet() {
      axios.get(api.userWallet)
          .then((res) => {
            //返回数字银行卡的余额
            this.e_cnyBankNum = res.data.coinList.find(item => item.name_en === "e_cny_bank").num;
            this.is_buy = res.data.coinList.find(item => item.name_en === "e_cny").is_buy;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    setPwd() {
      // if (this.is_buy == 0) {
      //   // this.$dialog.alert({
      //   //   title: '提示',
      //   //   message: '激活数字人民币才有资格设计取款密码',
      //   //   theme: 'round-button',
      //   // }).then(() => {
      //     this.$router.push({
      //       name: "fund",
      //     });
      //   // });
      //   return;
      // }
      if (this.bankCardInfo.status == 1) {
        this.$router.push({
          name: "buyAssets",
          query: {
            title: "立即支付",
            source: 4,
            price: 300,
            name: '激活费',
            id: 4,
          },
        });
        return;
      }
      this.$router.push({
        name: "setPayPwd",
        query: {
          id: this.bankCardInfo.id,
        }
      });
    },
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },


}
</script>

<style scoped lang="less">

</style>
