<template>
  <div class="h-full w-full bg-invite">
    <nav-bar
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="p-2 page-content-2 ">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="true"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div class="p-2">
              <div class="w-full  items-center">
                <img src="@/assets/img/invite/icon.png" class="w-full">
              </div>
              <div class="bg-F19149 rounded text-center mx-4 text-black text-base mb-4">
                <span class="text-white">注册 </span>免费领取一个金币 价值380万
              </div>
              <div class="text-lg text-white mb-2">邀请实名注册送豪礼</div>
              <div class="flex flex-row items-center bg-white rounded-md mb-2 p-2 text-base"
                   v-for="(item,index) in list"
                   :key="index">

                <img :src="item.icon" class="w-10 h-10 mr-2">
                <div class="flex-1 relative">
                  <div v-if="item.invite_identity_user>0">邀请<span class="text-red-500 font-bold">{{ item.invite_identity_user }}</span>位实名注册</div>
                  <div v-else>注册并实名认证</div>
                  <div class="flex flex-row text-red-600 items-start mt-3">
                    <div class="bg-red-600 rounded text-sm text-white px-1">送</div>
                    <div>{{ item.describe }}</div>
                  </div>

                  <div
                      class="rounded-full  font-bold bg-E7CA98 py-1 px-2 text-sm flex flex-row justify-center items-center absolute right-0 top-0"
                      style="height: 30px;"
                      @click="jumpToPage(item,index)"
                      v-html="getFormatLabel(item.status)"
                      :class="classObject(item)"
                  >
                  </div>
                </div>
              </div>
            </div>
          </list>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>


import {List, NavBar, PullRefresh,} from "vant";
import {mapActions, mapState} from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "index",
  components: {List, NavBar, PullRefresh,},
  data() {
    return {
      type: 0,
      dialog: false,
      name: '',
      phone: '',
      address: '',
      list: [],
      recommendRecords: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.getUserInfo();
    this.loadMore()
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    getFormatLabel(status) {
      switch (status) {
        case 2:
          return '立即领取'
        case 3:
          return "&nbsp;&nbsp;已领取&nbsp;&nbsp;"
        default:
          return "&nbsp;&nbsp;未达标&nbsp;&nbsp;"
      }
    },
    classObject(item) {
      switch (item.status) {
        case 2:
          return 'btn-receipt';
        case 3:
          return 'btn-complete';
        default:
          return 'btn-normal';
      }
    },
    jumpToPage(item,index) {
      switch (item.status){
        case 2:
          this.receiveAward(item.id)
          break;
        case 3:
          this.$toast('该奖项已被领取');
          break;
        default:
          if(index==0)
          {
            this.$router.push({name: 'realNameAuth'})
          }
          else{
            this.$router.push({name: 'inviteUser'})
          }
          break;
      }
    },
    receiveAward(id) {
      axios
          .post(api.receiveAward, {id})
          .then((res) => {
            this.$toast("领取成功");
            this.loadMore();
            this.dialog = false;
          })
          .catch((err) => {
            this.dialog = false;
            err.msg && this.$toast(err.msg);
          });

    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            this.list = res.data.inviteConfigList
            this.loading = false
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = true
            err.msg && this.$toast(err.msg);
          });
    },

  },
};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}
//
//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}

.grade-icon {
  height: 46px;
}

.get-icon {
  height: 46px;
}

.title-icon {
  width: 220px;
}
.bg-invite /deep/ .van-nav-bar .van-icon {
  color: #fff;
}
.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-invite {
  background-image: url('../../assets/img/invite/bg_invite.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.btn-normal {
  background: #8B7073;
  color: white;
}

.btn-complete {
  background: #EAD6BD;
  color: #B89494;
}

.btn-receipt {
  background: linear-gradient(90deg, #FF5455, #FFB533);
  color: white;
}
</style>
