<template>
  <div class="page-container h-screen bg-page bg-white">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full bg-transparent"
        :border="false"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full ">
        <!-- Header Image -->
        <div class="h-36">
        </div>

        <!-- Main Content -->
        <div class="bg-white rounded-t-lg p-4">

          <h2 class="text-lg font-bold mb-4">云家身份证</h2>

          <div class="text-center">
            <Uploader
                v-model="fileList"
                :max-count="1"
                :after-read="afterRead"
                @delete="onDelete"
                :max-size="358 * 441"
                @oversize="onOversize"
            >
            </Uploader>
            <p class="text-blue-500 mt-2">上传证件照</p>
          </div>
          <div class="relative">
            <id-card-generator
                v-if="identifyInfo.name"
                :card-data="identifyInfo"
                @card-generated="onCardGenerated"
            />
            <img v-else src="@/assets/img/yjsfz/ic_sfz.png" alt="" class="w-full mt-4 " />
<!--          <img src="@/assets/img/yjsfz/ic_sfz.png" alt="" class="w-full mt-4 " />-->
<!--            <div class="absolute text-sm" style="left:70px;top:35px;">{{identifyInfo.name}}</div>-->
<!--            <div class="absolute text-sm" style="left:70px;top:60px;">{{identifyInfo.sex}}</div>-->
<!--            <div class="absolute text-sm" style="left:70px;top:93px;">{{identifyInfo.year}}</div>-->
<!--            <div class="absolute text-sm" style="left:130px;top:93px;">{{identifyInfo.month}}</div>-->
<!--            <div class="absolute text-sm" style="left:170px;top:93px;">{{identifyInfo.day}}</div>-->
<!--            <div class="absolute text-sm" style="left:70px;top:124px;">{{identifyInfo.get_address}}</div>-->
<!--            <div class="absolute text-sm" style="left:130px;top:202px;">{{identifyInfo.id_card_no}}</div>-->
<!--            <img  class="absolute" style="left:220px;top:50px;width:100px;" :src="identifyInfo.head_img" alt="" />-->
<!--          </div>-->
<!--          <img :src="cardImageUrl" alt="" class="w-full mt-4">-->
          <img src="@/assets/img/index/sfz_1.png" alt="" class="w-full mt-2 mb-4">
          <Button v-if="identifyInfo.is_express == 0" type="danger" round block class="mt-4" @click="onSubmit">申请发货</Button>

          <div class="mt-4 text-sm">
            <div v-if="identifyInfo.get_name" class="bg-gray-200 p-4 m-2 rounded-2xl " @click.stop="ToMyAddress">
              <div class="mb-4 flex justify-between items-center">
                <div class=" text-base font-semibold">配送信息</div>
                <div class=" text-red-500 cursor-pointer  " @click.stop="ToMyAddress">修改</div>
              </div>
              <div class="flex flex-row justify-between items-center">
                <div class="mb-1">姓名：{{ identifyInfo.get_name }}</div>
                <div class="mr-10">电话：{{ identifyInfo.get_mobile }}</div>
              </div>
              <div class="mb-1">地址：{{ identifyInfo.get_address }}</div>
              <!--              <div>制卡进度：<span class="text-blue-500">制卡中</span></div>-->
            </div>
            <div v-else class="bg-gray-200 p-4 m-2 rounded-2xl " @click="ToMyAddress">
              <div class="mb-4 text-base font-semibold">配送信息</div>
              <div class="mb-4 text-base text-center">点击完善配送信息</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import {mapActions, mapState} from "vuex";
import {Button, NavBar, Uploader} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import IdCardGenerator from "@/components/IdCardGenerator";

export default {
  components: {NavBar,  Button, Uploader,IdCardGenerator},
  computed: {
    ...mapState("user", ["bankCardInfo", "identifyInfo"]),
  },

  mounted() {
    this.getIdentifyInfo();
    this.getBankCardInfo();
  },
  data() {
    return {
      title: this.$route.meta.title,
      fileList: [],
      show: false,
      cardImageUrl:null,
    }
  },
  methods: {
    ...mapActions("user", ["getBankCardInfo", "getIdentifyInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      if (isNullOrEmpty(this.identifyInfo)||this.identifyInfo.status!==2) {
        this.$dialog.alert({
          title: '提示',
          message: '先实名认证',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      // if (isNullOrEmpty(this.identifyInfo)||isNullOrEmpty(this.identifyInfo.get_address)) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '请完善配送信息',
      //   })
      //       .then(() => {
      //         this.ToMyAddress();
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      // if (isNullOrEmpty(this.identifyInfo)||isNullOrEmpty(this.identifyInfo.head_img)) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '请先上传证件照',
      //   })
      //       .then(() => {
      //
      //       })
      //       .catch(() => {
      //       });
      //   return
      // }
      this.$router.push({
        name: "buyAssets",
        query: {
          title: "立即支付",
          source:5,
          price:300,
          name:'邮寄费',
          id:3,
        },
      });
      return;

    },
    beforeRead(file) {
      // 验证文件类型和大小
      const isImage = file.type.indexOf('image') !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$toast('请上传图片文件');
      }
      if (!isLt2M) {
        this.$toast('图片大小不能超过 2MB!');
      }

      return isImage && isLt2M;
    },
    afterRead(file) {
      this.avatar = file.content;
      // console.log("uploadImage",file.file);
      const formData = new FormData();
      formData.append('image', file.file);

      axios.post(api.uploadImage, formData)
          .then((res) => {
            console.log("uploadImage", res);
            this.uploadAvatar(res.data.img_path);
          })
          .catch((e) => e.msg && this.$toast(e.msg));
    },
    onOversize(file) {
      console.log(file);
      this.$toast('文件尺寸大小不能超过358 * 441');
    },
    uploadAvatar(path) {
      console.log("uploadAvatar", path);
      const formData = new FormData();
      formData.append('head_img', path);

      axios.post(api.identifyHeadImg,formData)
          .then((res) => {
            this.getIdentifyInfo();
          })
          .catch((e) => {
            console.log('uploadAvatar error',e)
            e.msg && this.$toast(e.msg)
          });
    },
    onDelete() {
      this.fileList = [];
    },

    ToMyAddress() {
      this.$router.push({
        name: "bankCardAddress",
        query: {
          id: this.identifyInfo.id,
          get_name: this.identifyInfo.get_name,
          get_mobile: this.identifyInfo.get_mobile,
          get_address: this.identifyInfo.get_address,
          from: "yjsfz",
        },
      });
    },
    onCardGenerated(cardImageUrl) {
      // console.log('ID card generated:', cardImageUrl);
      // 这里可以处理生成的图片URL，比如显示或下载
    }
  },

}
</script>

<style scoped lang="less">
.bg-page /deep/ .van-nav-bar .van-icon {
  color: #ffffff;
}

.bg-page /deep/ .van-nav-bar__title {
  color: #fff;
}

.bg-page {
  background-image: url('../../assets/img/yjsfz/bg_sfz.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>
