<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="rounded-sm bg-white p-2 m-2">
          <div class="password-input ">
            <div class="p-2">支付密码:</div>
            <password-input
                :value="password"
                :focused="keyboardShow === 'password'"
                @focus="showKeyboard('password')"
            />
          </div>
          <div class="password-input mt-1">
            <div class="p-2">确认支付密码:</div>
            <password-input
                :value="confirmPassword"
                :focused="keyboardShow === 'confirmPassword'"
                @focus="showKeyboard('confirmPassword')"
            />
          </div>
          <div class="mt-8 mb-2 mx-2">
            <Button round block type="info" color="#FF5848" @click="submitPassword" :disabled="!isValid">确认</Button>
          </div>
          <number-keyboard
              :show="!!keyboardShow"
              @input="onInput"
              @delete="onDelete"
              @blur="hideKeyboard"
              :maxlength="6"
          />
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import {Button, NavBar, NumberKeyboard, PasswordInput} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, Button, NumberKeyboard, PasswordInput},

  data() {
    return {
      title: this.$route.meta.title,
      password: '',
      confirmPassword: '',
      keyboardShow: '',
      id: this.$route.query.id,
    }
  },
  computed: {
    isValid() {
      return this.password.length === 6 &&
          this.confirmPassword.length === 6 &&
          this.password === this.confirmPassword;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    showKeyboard(type) {
      this.keyboardShow = type;
    },
    hideKeyboard() {
      this.keyboardShow = '';
    },
    onInput(key) {
      if (this.keyboardShow === 'password' && this.password.length < 6) {
        this.password += key;
      } else if (this.keyboardShow === 'confirmPassword' && this.confirmPassword.length < 6) {
        this.confirmPassword += key;
      }
    },
    onDelete() {
      if (this.keyboardShow === 'password') {
        this.password = this.password.slice(0, -1);
      } else if (this.keyboardShow === 'confirmPassword') {
        this.confirmPassword = this.confirmPassword.slice(0, -1);
      }
    },
    async submitPassword() {
      if (!this.isValid) {
        this.$toast('请输入有效的6位数字密码，并确保两次输入一致');
        return;
      }
      const {confirmPassword, password} = this;
      if (isNullOrEmpty(password)) {
        this.$toast("请输入6位数密码");
        return
      }

      if (isNullOrEmpty(confirmPassword)) {
        this.$toast("请再次输入6位数密码");
        return
      }

      try {
        this.$toast.loading({
          message: "提交中...",
          forbidClick: true,
        });
        await axios.post(api.updateTradePassword, {
          re_password: confirmPassword,
          password,
          id: this.id,
        })
        this.$dialog.alert({
          title: '提示',
          message: '设置成功',
          theme: 'round-button'
        }).then(() => {
          this.onClickLeft();
        })
      } catch (err) {
        err.msg && this.$toast(err.msg);
      } finally {
        this.$toast.clear();
      }
    }

  },

}
</script>

<style scoped lang="less">
.payment-password-setup {
  padding: 20px;
}

.password-input {
  margin-bottom: 20px;
}
</style>
