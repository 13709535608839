<template>
  <div class="min-h-screen bg-gray-100">
    <van-nav-bar
        :title="title"
        left-arrow
        @click-left="onClickLeft"
        :border="false"
        class="bg-transparent"
    />
    <div class="p-4">
      <div class="bg-white rounded-lg shadow-md p-4">
        <van-field
            v-model="form.name"
            label="收货人"
            placeholder="请输入真实姓名"
            :border="false"
            input-align="left"
            class="font-bold text-lg  "
        />
        <van-field
            v-model="form.phone"
            label="手机号"
            placeholder="请输入收货手机号码"
            :border="false"
            maxlength="11"
            input-align="left"
            class="font-bold text-lg mt-4 border-t border-gray-300"
        />
        <van-field
            v-model="form.address"
            label="地址"
            placeholder="请输入详细地址"
            :border="false"
            input-align="left"
            class="font-bold text-lg mt-4 border-t border-gray-300"
        />
        <!--          <van-field-->
        <!--              v-model="form.detailAddress"-->
        <!--              label="详细地址"-->
        <!--              type="textarea"-->
        <!--              placeholder="请输入详细地址"-->
        <!--              :border="false"-->
        <!--              input-align="left"-->
        <!--              class="font-bold text-lg mt-4 border-t border-gray-300"-->
        <!--          />-->
        <div class="mt-8">
          <van-button round block type="danger" @click="onSubmit" class="bg-red-500 border-red-500">
            确认
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {NavBar, Form, Field, Button} from 'vant';
import {isNullOrEmpty} from "@/utils";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      //
      title: this.$route.meta.title,
      query: this.$route.query,
      form: {
        id:  this.$route.query.id,
        name: this.$route.query.name,
        phone: this.$route.query.phone,
        address: this.$route.query.address,
      },
    };
  },
  methods: {
    onClickLeft() {
      // 返回上一页
      this.$router.go(-1);
    },
    onSubmit() {
      const {id,name, phone, address} = this.form;

      if (isNullOrEmpty(name)) {
        this.$toast("请输入真实姓名");
        return;
      }
      if (isNullOrEmpty(phone)) {
        this.$toast("请输入收货手机号码");
        return;
      }
      if (isNullOrEmpty(address)) {
        this.$toast("请输入详细地址");
        return;
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      const formData = new FormData();
      formData.append('id', id);
      formData.append('get_name', name);
      formData.append('get_mobile', phone);
      formData.append('get_address', address);
      axios
          .post(api.awardAddress, formData)
          .then((res) => {
            this.$toast.clear();
            this.$toast("提交成功");
            this.onClickLeft();
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    }
  },
};
</script>

<style>

</style>
