<template>
  <div class="my-2">
    <div class="text-base font-bold text-black mb-2">资产记录</div>
    <div class="bg-white flex flex-col  rounded-lg p-2">
      <div class="w-full text-black text-base flex flex-row flex-wrap">
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('awardRecord')">
          <img src="@/assets/img/profile/cjjl.png" alt="" style="height: 25px;" class="mb-1">
          <span>抽奖记录</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('buyBonusList')">
          <img src="@/assets/img/profile/gmjl.png" alt="" style="height: 25px;" class="mb-1">
          <span>投资记录</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('withdrawRecord')">
          <img src="@/assets/img/profile/txjl.png" alt="" style="height: 25px;" class="mb-1">
          <span>提现记录</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('fundDetails')">
          <img src="@/assets/img/profile/zjmx.png" alt="" style="height: 25px;" class="mb-1">
          <span>资金明细</span>
        </div>

      </div>
    </div>
    <div class="text-base font-bold text-black mb-2 mt-3">其他</div>
    <div>
      <cell title="团队管理" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('team')">
        <template #icon>
          <img src="@/assets/img/profile/team.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="实名认证" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('realNameAuth')">
        <template #icon>
          <img src="@/assets/img/profile/smrz.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="银行卡绑定" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('bindBankCard')">
        <template #icon>
          <img src="@/assets/img/profile/yhbd.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="官方群聊" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('contactUs')">
        <template #icon>
          <img src="@/assets/img/profile/zxkf.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="App下载" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('downloadApp')">
        <template #icon>
          <img src="@/assets/img/profile/download.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="邀请好友" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('invite')">
        <template #icon>
          <img src="@/assets/img/profile/share.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
      <cell title="修改密码" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('editPassword')">
        <template #icon>
          <img src="@/assets/img/profile/password.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>

      <cell title="收货地址" icon="@/assets/img/profile/team.png" is-link @click="jumpToPage('MyAddress')">
        <template #icon>
          <img src="@/assets/img/profile/Address-icon.png" alt="" style="height: 25px;" class="mr-1">
        </template>
      </cell>
    </div>
  </div>
</template>

<script>

import {Cell} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  components: {Cell},
  props: ['pageKey'],
  data() {
    return {
      bankCardInfo: {},
    };
  },

  mounted() {
    this.getBankCardInfo();
  },

  methods: {
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      if (name == 'MyAddress') {
        this.$router.push({
          name: "bankCardAddress",
          query: {
            id: this.bankCardInfo.id,
            get_name: this.bankCardInfo.get_name,
            get_mobile: this.bankCardInfo.get_mobile,
            get_address: this.bankCardInfo.get_address,
          },
        });
        return
      }
      this.$router.push({name});
    },
    getBankCardInfo() {
      axios.get(api.myBankOrder)
          .then((res) => {
            this.bankCardInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background: #FFFFFF;
  opacity: 0.9;
  box-shadow: 0px 0px 11.6px 0px #C1C1C140;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: #898989;
  font-size: 14px;
  padding: 10px 0;
  margin-bottom: 10px
}

.count-img {
  height: 26px;
  margin-bottom: 6px;
}
</style>
