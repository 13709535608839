<template>
  <div class="h-full w-full flex flex-col">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              :finished-text="list.length>10?'没有更多了':''"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div v-for="(item, index) in list" :key="index" class="flex flex-col bg-white p-2 border-line"
                 :class="{ 'border-b': index !== list.length - 1 }">
              <div class="flex flex-row ">
                <div class="text-sm text-b333 font-bold flex-1">{{item.content}}</div>
                <div class="text-sm text-red-500">{{item.type_name||""}}</div>

              </div>
              <div class="flex flex-row my-1">
                <div class="text-sm text-b333 flex-1">{{ item.total }}</div>
                <div class="text-sm text-b333">{{ item.created_at | timeFormat('YYYY-MM-DD HH:mm') }}</div>
<!--                <div class="text-sm " :class="getStatusColorClass(item.type)">{{ item.type | getTypes }}{{-->
<!--                    item.amount-->
<!--                  }}-->
<!--                </div>-->
              </div>
            </div>
          </list>
          <Empty v-if="list.length === 0" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>
<script>


import TitleBar from "@/components/TitleBar";
import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List, NavBar, PullRefresh,} from "vant";
import {EnumOperations, getFundTypeLabel, FundType} from "@/config";
import moment from "moment";

export default {
  components: { NavBar, List, Empty, PullRefresh},
  data() {
    return {
      getFundTypeLabel,
      FundType,
      title: this.$route.meta.title,
      totalPage: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  filters: {
    getTypes(value) {
      if (value == EnumOperations.INCREASE) {
        return "+"
      } else {
        return "-"
      }
    },
  },
  methods: {
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    refreshData() {
      this.refreshing = true
      this.pageNo = 1
      this.totalPage = 0
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.balanceLog, {
            params:{
              pageNo: this.pageNo,
              pageSize: this.pageSize,
            }
          })
          .then((res) => {
            if (this.pageNo === 1) {
              this.list = res.data.list;
              this.count = res.data.count;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.loading = false
            this.refreshing = false
            if (this.list.length < this.count) {
              this.pageNo++;
            } else {
              this.finished = true;
            }
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            this.finished = true;
            err.msg && this.$toast(err.msg);
          });
    },
    getStatusColorClass(status) {
      switch (status) {
        case EnumOperations.INCREASE:
          return 'text-green-500'; // 返回绿色文字样式类
        case EnumOperations.DECREASE:
          return 'text-red-500'; // 返回红色文字样式类
        default:
          return 'text-b333'; // 默认文字样式类
      }
    },
    getWalletTypeStr(wallet_type) {
      switch (wallet_type) {
        case 1:
          return '会员注册';
        case 2:
          return '会员实名认证';
        case 3:
          return '会员签到';
        case 4:
          return '推荐用户注册';
        default:
          return '推荐用户实名认证';
      }
    },

  },
};
</script>

<style scoped lang="less">

</style>
