<template>
  <div class="foot_menu w-full">
    <Tabbar v-model="active" active-color="#FE4B4C" inactive-color="#999999" :border="false" @change="changeTab">
      <TabbarItem
          v-for="(item, index) in menuList"
          :key="index"
      >
        <span class="single-line-text">{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.sel_pic : item.pic"/>
        </template>
      </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import {Tabbar, TabbarItem} from "vant";

export default {
  props: ['pageKey'],
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: "首页",
      menuList: [
        {
          "title": "首页",
          "isShow": 1,
          "url": "/home",
          "pic": require('@/assets/img/nav/menu_home.png'),
          "sel_pic": require('@/assets/img/nav/menu_home_checked.png'),
        },
        // {
        //   "title": "邀请",
        //   "isShow": 1,
        //   "url": "/invite",
        //   "pic": require('@/assets/img/nav/menu_invite.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_invite_checked.png'),
        // },
        {
          "title": "数字人民币",
          "isShow": 1,
          "url": "/fund",
          "pic": require('@/assets/img/nav/menu_fund.png'),
          "sel_pic": require('@/assets/img/nav/menu_fund_checked.png'),
        },


        // {
        //   "title": "客服",
        //   "isShow": 1,
        //   "url": this.$config.KF_URL,
        //   "pic": require('@/assets/img/nav/menu_kf.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_kf_checked.png'),
        // },
        {
          "title": "恢复资产",
          "isShow": 1,
          "url": "/recover",
          "pic": require('@/assets/img/nav/menu_money.png'),
          "sel_pic": require('@/assets/img/nav/menu_money_checked.png'),
        },
        {
          "title": "五行商会",
          "isShow": 1,
          "url": "/shanghui",
          "pic": require('@/assets/img/nav/menu_debt.png'),
          "sel_pic": require('@/assets/img/nav/menu_debt_checked.png'),
        },
        {
          "title": "我的",
          "isShow": 1,
          "url": "/profile",
          "pic": require('@/assets/img/nav/menu_my.png'),
          "sel_pic": require('@/assets/img/nav/menu_my_checked.png'),
        }],
    };
  },
  created() {
    this.$bus.$on('active', this.handleMessage);
  },
  beforeDestroy() {
    this.$bus.$off('active', this.handleMessage); // 在组件销毁前移除监听器
  },
  mounted() {
    this.init();
  },
  methods: {
    handleMessage(index) {
      this.active = index;
    },
    init() {
      const title = this.$route.meta.title;
      console.log('title', title)
      switch (title) {
        case '首页':
          this.active = 0
          break;
        // case '邀请':
        //   this.active = 1
        //   break;
        case '数字人民币':
          this.active = 1
          break;
        // case "五行币股权":
        //     this.active = 2
        //     break;
          // case '我的':
          //   this.active = 4
          //   break;
        // case '客服':
        //   this.active = 3
        //   break;
        case '恢复资产':
          this.active = 2
          break;
        case "五行商会":
          this.active = 3
          break;
        case '我的':
          this.active = 4
          break;

      }
    },
    changeTab(index) {
      this.active = index;
      const item = this.menuList[index];
      if (item.title == '客服') {
        window.location.href = item.url;
        return;
      }
      this.navigateTo(item.url);
    },
    navigateTo(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.foot_menu {
  height: 50px;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-tabbar-item__icon img {
  height: 1.5rem;
}

.single-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}
</style>
