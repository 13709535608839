<template>
  <div class="login_box relative">
    <img src="../assets/img/login/ic_logo.png" style="width: 122px" class="self-center"/>

    <h1 class="text-2xl font-bold text-white self-center mb-10 "> 五行天下 </h1>
<!--    <h1 class="text-sm text-white self-center mb-10"> HAN HONG LOVE FOUNDATION </h1>-->

    <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-lg" id="app" data-v-app="">

      <div class="flex justify-center mb-6">
        <button class="text-primary border-b-2 border-red-400 focus:outline-none">登录</button>
        <button class="text-gray-400 border-b-2 border-transparent focus:outline-none  ml-8"
                @click="$router.push('/register')">注册
        </button>
      </div>
      <div class="space-y-4">
        <div class="flex items-center border-b border-gray-300 py-2">
          <i class="iconfont icon-iconfontshouji text-gray-400 mr-2"/>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="mobile"
                 maxlength="11"
                 placeholder="请输入手机号" type="tel">
        </div>

        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password"
                 placeholder="请输入密码" type="password">
        </div>
      </div>
      <button class="w-full bg-primary text-white py-2 rounded mt-10" @click="onLogin"> 立即登录</button>
      <div class="flex justify-center mt-10" @click="jumpToPage('editPassword')">忘记密码？</div>
    </div>
    <div class="flex flex-row items-center justify-between mt-3">
      <div class="flex flex-col items-center justify-center " @click="jumpToPage('customService')">
        <img src="@/assets/img/index/kf.png" alt="" style="width: 30px;">
        <div class="text-white text-sm">客服</div>
      </div>
      <div class="flex flex-col items-center justify-center " @click="jumpToPage('contactUs')">
        <img src="@/assets/img/profile/zxkf_1.png" alt="" style="width: 30px;" >
        <div class="text-white text-sm">官方群聊</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import {Icon} from "vant";
import UserStorage from "@/utils/user_storage";
import QRCode from "qrcodejs2";
import {CUSTOME_SERVICE_CHAT_URL, GROUP_CHAT_URL} from "@/config";

export default {
  name: "Login",
  components: {Icon},
  data() {
    return {
      mobile: "",
      password: "",
      loading: false,
    };
  },
  mounted() {
    this.loginByToken();
  },
  methods: {
    jumpToPage(name) {
      console.log('jumpTo',name)
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }

      this.$router.push({name});
    },
    loginByToken() {
      try {
        const token = this.$route.query.token;
        if (token) {
          UserStorage.setToken(token);
          window.token = token;
          this.$router.replace({name: "home"});
        }
      } catch (e) {
        console.log(e);
      }
    },
    onLogin() {
      // console.log("登录中1...");
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });

      const formData = new FormData();
      formData.append('account', this.mobile);
      formData.append('password', this.password);
      axios
          .post(api.login, formData)
          .then((res) => {
            this.$toast.clear();
            console.log("登录成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    generateGFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("gf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        this.url = GROUP_CHAT_URL;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 100,
          height: 100
        });
      }
    },
    generateKFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("kf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        new QRCode(qrcodeContainer, {
          text: CUSTOME_SERVICE_CHAT_URL,
          width: 100,
          height: 100
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.login_box {
  background-image: url('../assets/img/login/bg_login.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  padding: 80px 25px 25px 25px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    font-size: 24px;
    font-weight: 550;
    color: #fff;
  }
}
</style>
